import { isNotEmptyOrWhitespace } from './string.util';

interface User {
  email: string;
  firstName: string | null;
  lastName: string | null;
}

export const getUserNameFallback = (user: User) => {
  return `${user.email.split('@')[0]}@...`;
};

export const getFullName = (user: {
  firstName: string | null;
  lastName: string | null;
}) => {
  if (!user) {
    return '';
  }

  const { firstName, lastName } = user;
  const parts = [firstName, lastName].filter(
    isNotEmptyOrWhitespace,
  ) as string[];

  return parts.join(' ');
};

export const getUserName = (user: User) => {
  const parts = [user.firstName, user.lastName].filter(isNotEmptyOrWhitespace);
  if (parts.length === 0) {
    return getUserNameFallback(user);
  }

  return parts.join(' ');
};

export const getUserShortName = (user: User) => {
  const parts = [user.firstName, user.lastName].filter(
    isNotEmptyOrWhitespace,
  ) as string[];

  if (parts.length === 0) {
    return getUserNameFallback(user);
  }

  if (parts.length === 1) {
    return parts[0];
  }

  return `${parts[0]} ${parts[1][0]}.`;
};
