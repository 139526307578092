import { hc } from 'hono/client';

import {
  auth,
  client,
  contractorOrganization,
  files,
  routerOrganization,
  user,
} from './api';

// this is a trick to calculate the type when compiling
const typedRouterClient = hc<typeof routerOrganization>('/api/routers/');
type RouterClient = typeof typedRouterClient;

export const hcRouterClient = (...args: Parameters<typeof hc>): RouterClient =>
  hc<typeof routerOrganization>(...args);

const typedContractorClient =
  hc<typeof contractorOrganization>('/api/contractors/');
type ContractorClient = typeof typedContractorClient;

export const hcContractorClient = (
  ...args: Parameters<typeof hc>
): ContractorClient => hc<typeof contractorOrganization>(...args);

const typedClientClient = hc<typeof client>('/api/clients/');
type ClientClient = typeof typedClientClient;

export const hcClientClient = (...args: Parameters<typeof hc>): ClientClient =>
  hc<typeof client>(...args);

const typedFileClient = hc<typeof files>('/api/files/');
type FileClient = typeof typedFileClient;
export const hcFileClient = (...args: Parameters<typeof hc>): FileClient =>
  hc<typeof files>(...args);

const typedAuthClient = hc<typeof auth>('/api/auth/');
type AuthClient = typeof typedAuthClient;

export const hcAuthClient = (...args: Parameters<typeof hc>): AuthClient =>
  hc<typeof auth>(...args);

const typedUserClient = hc<typeof user>('/api/users/');
type UserClient = typeof typedUserClient;

export const hcUserClient = (...args: Parameters<typeof hc>): UserClient =>
  hc<typeof user>(...args);
