import axios, { AxiosError, AxiosInstance } from 'axios';
import type { ClientRequestOptions, ClientResponse } from 'hono/client';

import {
  hcAuthClient,
  hcFileClient,
  hcContractorClient,
  hcRouterClient,
  hcUserClient,
} from '@proptly/api/hc.js';
import {
  attemptRefreshingSession,
  fetchWithRefresh,
} from '@proptly/ui-core/lib/auth/utils';

import {
  API_URL,
  AUTH_API_URL,
  CHAT_API_URL,
  CONTRACTOR_API_URL,
  FILE_API_URL,
  ROUTING_API_URL,
  TIMELINE_API_URL,
} from './constants';

export type ExtractResponseType<
  T extends (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    args: any,
    options?: ClientRequestOptions<unknown>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<ClientResponse<any, any>>,
> = Awaited<ReturnType<Awaited<ReturnType<T>>['json']>>;

export const routerApiClient = hcRouterClient(`${API_URL}/api/routers`, {
  init: {
    credentials: 'include',
  },
  fetch: fetchWithRefresh,
});

export const contractorApiClient = hcContractorClient(
  `${API_URL}/api/contractors`,
  {
    init: {
      credentials: 'include',
    },
    fetch: fetchWithRefresh,
  },
);

export const fileApiClient = hcFileClient(`${API_URL}/api/files`, {
  init: {
    credentials: 'include',
  },
  fetch: fetchWithRefresh,
});

export const authApiClient = hcAuthClient(`${API_URL}/api/auth`, {
  init: {
    credentials: 'include',
  },
  fetch: fetchWithRefresh,
});
export const userApiClient = hcUserClient(`${API_URL}/api/users`, {
  init: {
    credentials: 'include',
  },
  fetch: fetchWithRefresh,
});

const contractorClient = axios.create({
  baseURL: `${CONTRACTOR_API_URL}/api`,
  withCredentials: true,
});

const routingClient = axios.create({
  baseURL: `${ROUTING_API_URL}/api`,
  withCredentials: true,
});

const authClient = axios.create({
  baseURL: `${AUTH_API_URL}/api`,
  withCredentials: true,
});

const timelineClient = axios.create({
  baseURL: `${TIMELINE_API_URL}/api`,
  withCredentials: true,
});

const chatClient = axios.create({
  baseURL: `${CHAT_API_URL}/api`,
  withCredentials: true,
});

const fileClient = axios.create({
  baseURL: `${FILE_API_URL}/api`,
  withCredentials: true,
});

const addAuthInterceptors = (client: AxiosInstance) => {
  client.interceptors.response.use(undefined, async (error: AxiosError) => {
    if (
      error.response?.status !== 401 ||
      !error.config ||
      // @ts-expect-error - retried is added by us
      error.config.retried
    ) {
      return Promise.reject(error);
    }
    // @ts-expect-error - retried is added by us
    error.config.retried = true;

    // refresh session
    try {
      await attemptRefreshingSession();

      return client.request(error.config);
    } catch {
      return Promise.reject(error);
    }
  });
};

// Add interceptors to all clients
addAuthInterceptors(routingClient);
addAuthInterceptors(contractorClient);
addAuthInterceptors(authClient);
addAuthInterceptors(timelineClient);
addAuthInterceptors(chatClient);
addAuthInterceptors(fileClient);
export {
  contractorClient,
  routingClient,
  authClient,
  timelineClient,
  chatClient,
  fileClient,
};
