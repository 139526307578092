import { useCallback } from 'react';

import { useCurrentLang } from '@proptly/locale';
import { DateLike } from '@proptly/shared';

import { omitDatestring } from '../../utils';

/**
 * @deprecated moved to ui-core
 */
export const useWeekdayWithTimeFormatTimestamp = (
  options: {
    useBrowserDefaultLocale?: boolean;
  } = {
    useBrowserDefaultLocale: false,
  },
) => {
  const appLang = useCurrentLang();
  const locale = options?.useBrowserDefaultLocale ? undefined : appLang;

  return useCallback(
    (date: DateLike) => {
      const preparedDate = omitDatestring(date);
      const formatter = Intl.DateTimeFormat(locale, {
        weekday: 'short',
        hour: 'numeric',
        minute: '2-digit',
      });

      return formatter.format(preparedDate);
    },
    [locale],
  );
};
