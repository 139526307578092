export enum StringLengthsEnum {
  CharsLength10 = 10,
  CharsLength50 = 50,
  CharsLength75 = 75,
  CharsLength100 = 100,
  CharsLength150 = 150,
  CharsLength200 = 200,
  CharsLength250 = 250,
  CharsLength500 = 500,
  CharsLength600 = 600,
  CharsLength750 = 750,
  CharsLength800 = 800,
  CharsLength1000 = 1000,
  CharsLength2000 = 2000,
  CharsLength5000 = 5000,
}
