import { useMutation, useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import * as z from 'zod';

import {
  getJsonOrThrow,
  ExtractResponseType,
  authApiClient,
  userApiClient,
} from '../../lib/axios';
import { posthogClient } from '../analytics';
import {
  AUTH_APP_URL,
  APP_DOMAIN,
  API_URL,
  CONTRACTOR_ID_COOKIE_NAME,
  ROUTING_ACCOUNT_ID_COOKIE_NAME,
  CONTRACTOR_EMPLOYEE_APP_URL,
  ROUTING_APP_URL,
} from '../constants';
import { MobilePushNotifications } from '../notifications/mobile-push-notifications';

export type LoginParams = Parameters<(typeof authApiClient.login)['$post']>[0];
export type CurrentUserResponse = (typeof userApiClient.me)['$get'];
export type CurrentUser = ExtractResponseType<CurrentUserResponse>;
export type LoginPayload = LoginParams['json'];
export type EmailLoginPayload = Omit<LoginPayload, 'type'>;

export const emailPasswordLoginSchema = z.object({
  email: z.string().email({ message: 'Please enter a valid email address' }),
  password: z
    .string()
    .min(6, { message: 'Password must be at least 6 characters' }),
});

export const login = async (payload: LoginPayload) => {
  const response = await authApiClient.login.$post({ json: payload });

  // TODO
  // Better and generic error handling
  const status = response.status as number;
  if (status === 401) {
    throw new Error('invalidCredentialsError');
  }

  if (status >= 400) {
    throw new Error('somethingWentWrongError');
  }

  return getJsonOrThrow(response);
};

export const useLogin = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: login,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['currentUser'] });
    },
  });
};

export function useIsLoggedIn() {
  // TODO
  // Implement this
  return false;
}

export type OrganizationRole = {
  organization: {
    id: string;
    name: string;
  };
  roles: string[];
};

export enum OrganizationType {
  ROUTING = 'routing',
  CONTRACTOR = 'contractor',
}

export function redirectToAuth() {
  const redirectToPath = window.location.href;
  const searchParams = new URLSearchParams();
  searchParams.set('redirectToPath', redirectToPath);

  window.location.assign(`${AUTH_APP_URL}?${searchParams.toString()}`);
}

export function redirectAutomaticallyIfPossible(user: CurrentUser) {
  if (user.isSuperuser) {
    return;
  }

  const totalOrganizations = [
    ...user.routerOrganizations,
    ...user.contractorOrganizations,
  ];

  if (totalOrganizations.length === 1) {
    const organisation = totalOrganizations[0];
    const type =
      user.routerOrganizations.length > 0
        ? OrganizationType.ROUTING
        : OrganizationType.CONTRACTOR;

    if (
      type === OrganizationType.ROUTING &&
      'routerOrganizationId' in organisation
    ) {
      if (organisation.routerOrganizationId) {
        setSelectedRouter(organisation.routerOrganizationId);
        window.location.assign(ROUTING_APP_URL);
      }
    } else if (
      type === OrganizationType.CONTRACTOR &&
      'contractorOrganizationId' in organisation
    ) {
      if (organisation.contractorOrganizationId) {
        setSelectedContractor(organisation.contractorOrganizationId);
        window.location.assign(CONTRACTOR_EMPLOYEE_APP_URL);
      }
    }
  }

  return;
}

export async function logout() {
  if (MobilePushNotifications.isRegistered) {
    await MobilePushNotifications.unregister();
  }
  // redirect to api.proptly.com/auth/logout
  if (posthogClient) {
    posthogClient.reset();
  }
  window.location.assign(`${API_URL}/api/auth/logout`);
}

export async function setSelectedContractor(contractorId: string) {
  Cookies.remove(ROUTING_ACCOUNT_ID_COOKIE_NAME);
  Cookies.set(CONTRACTOR_ID_COOKIE_NAME, contractorId, {
    domain: APP_DOMAIN,
    path: '/',
  });
}

export async function getSelectedContractor() {
  return Cookies.get(CONTRACTOR_ID_COOKIE_NAME);
}

export async function setSelectedRouter(routerId: string) {
  Cookies.remove(CONTRACTOR_ID_COOKIE_NAME);
  Cookies.set(ROUTING_ACCOUNT_ID_COOKIE_NAME, routerId, {
    domain: APP_DOMAIN,
    path: '/',
  });
}

export async function getSelectedRouter() {
  return Cookies.get(ROUTING_ACCOUNT_ID_COOKIE_NAME);
}

export function checkIsAdmin(roles: string[] | { role: string }[]) {
  return roles.some((role) => {
    if (typeof role === 'string') {
      return role === 'ADMIN';
    }

    return role.role === 'ADMIN';
  });
}

export function navigateToOrganization(
  organizationType: OrganizationType,
  organizationId: string,
) {
  if (organizationType === OrganizationType.ROUTING) {
    setSelectedRouter(organizationId);
    window.location.assign(ROUTING_APP_URL);
  } else if (organizationType === OrganizationType.CONTRACTOR) {
    setSelectedContractor(organizationId);
    window.location.assign(CONTRACTOR_EMPLOYEE_APP_URL);
  }
}

export async function attemptRefreshingSession() {
  await fetch(`${API_URL}/api/auth/refresh`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({}),
  });
}

export const fetchWithRefresh = async (
  input: RequestInfo | URL,
  init?: RequestInit,
): Promise<Response> => {
  let response = await fetch(input, init);

  if (response.status === 401) {
    try {
      await attemptRefreshingSession();
      response = await fetch(input, init);
    } catch {
      // logout
      // if we are on the auth_app we don't want to redirect to the auth_app
      if (window.location.href.includes(AUTH_APP_URL)) {
        return response;
      }

      logout();
    }

    return response;
  }

  return response;
};
