import ci from './ci.json';
import development from './development';
import production from './production.json';
import staging from './staging.json';
import { getEnv } from './utils';

const env = getEnv();

function getKey(key: string) {
  if (env === 'ci') {
    return ci[key as keyof typeof ci];
  }

  if (env === 'development') {
    return development[key as keyof typeof development];
  }

  if (env === 'staging') {
    return staging[key as keyof typeof staging];
  }

  return production[key as keyof typeof production];
}

export const MESSAGES_REFETCH_INTERVAL = 30_000;
export const NOTIFICATIONS_REFETCH_INTERVAL = 10_000; // 10 seconds
export const COUNTERS_DEBOUNCE_TIME = 5_000; // 5 seconds
export const DRAWER_CLOSE_DELAY = 300; // 300 ms

export const ENVIRONMENT = getKey('ENVIRONMENT') as string;
export const APP_DOMAIN = getKey('APP_DOMAIN') as string;
export const API_URL = getKey('API_URL') as string;
export const CONTRACTOR_API_URL = getKey('CONTRACTOR_API_URL') as string;
export const CONTRACTOR_EMPLOYEE_APP_URL = getKey(
  'CONTRACTOR_EMPLOYEE_APP_URL',
) as string;
export const CONTRACTOR_CLIENT_APP_URL = getKey(
  'CONTRACTOR_CLIENT_APP_URL',
) as string;
export const CONTRACTOR_SDK_URL = getKey('CONTRACTOR_SDK_URL') as string;
export const ROUTING_API_URL = getKey('ROUTING_API_URL') as string;
export const ROUTING_APP_URL = getKey('ROUTING_APP_URL') as string;
export const AUTH_API_URL = getKey('AUTH_API_URL') as string;
export const AUTH_APP_URL = getKey('AUTH_APP_URL') as string;
export const ADMIN_APP_URL = getKey('ADMIN_APP_URL') as string;
export const TIMELINE_API_URL = getKey('TIMELINE_API_URL') as string;
export const WEBFORM_APP_URL = getKey('WEBFORM_APP_URL') as string;
export const CHAT_API_URL = getKey('CHAT_API_URL') as string;
export const FILE_API_URL = getKey('FILE_API_URL') as string;
export const INTERCOM_APP_ID = getKey('INTERCOM_APP_ID') as string;
export const INTERCOM_API_BASE = getKey('INTERCOM_API_BASE') as string;
export const MAPBOX_API_KEY = getKey('MAPBOX_API_KEY') as string;
export const RECAPTCHA_SITE_KEY = getKey('RECAPTCHA_SITE_KEY') as string;
export const I18N_DEBUGGING = getKey('I18N_DEBUGGING') as string;
export const ROUTING_GTM_ID = getKey('ROUTING_GTM_ID') as string;
export const CONTRACTOR_GTM_ID = getKey('CONTRACTOR_GTM_ID') as string;
export const MOCK_SERVICE_WORKER_ENABLE = getKey(
  'MOCK_SERVICE_WORKER_ENABLE',
) as string;
export const METABASE_INSTANCE_URL = getKey('METABASE_INSTANCE_URL') as string;
