import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { DateLike } from '@proptly/shared';

import { getDateMeta } from '../../utils';

/**
 * @deprecated moved to ui-core
 */
export const useFormatterWithAlt = (formatFn: (date: DateLike) => string) => {
  const [t] = useTranslation();

  return useCallback(
    (date: DateLike) => {
      const dateMeta = getDateMeta(date);

      return dateMeta.isToday
        ? t('today')
        : dateMeta.isYesterday
        ? t('yesterday')
        : dateMeta.isTomorrow
        ? t('tomorrow')
        : formatFn(date);
    },
    [formatFn, t],
  );
};
