import { useTranslation } from 'react-i18next';

import { getFallbackLangs, Lang } from '../i18n';

export const useCurrentLang = () => {
  const { i18n } = useTranslation();

  // TODO fetch langugae from current user if possible

  return getFallbackLangs(i18n.language)[0] as Lang;
};
