import React, { useEffect } from 'react';

import posthog, { PostHog } from 'posthog-js';
import { PostHogProvider, usePostHog } from 'posthog-js/react';
import { useLocation } from 'react-router-dom';

import logo from '../../assets/logo-black.png';
import { Button } from '../../components/button';
import {
  Dialog,
  DialogDescription,
  DialogTitle,
  DialogHeader,
  DialogContent,
} from '../../components/dialog';
import { useAuth } from '../auth/useAuth';
import { POSTHOG_HOST, POSTHOG_KEY } from '../constants';

export let posthogClient: PostHog | undefined;
export function initAnalytics() {
  posthogClient = posthog.init(POSTHOG_KEY, {
    api_host: POSTHOG_HOST,
    capture_pageview: false,
    capture_pageleave: true, // Opt back in because disabling $pageview capture disables $pageleave events
  });
}

export const AnalyticsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  if (posthogClient) {
    return <PostHogProvider client={posthogClient}>{children}</PostHogProvider>;
  }

  return children;
};

export const identify = (userId: string) => {
  if (!posthogClient) {
    throw new Error('PostHog client not initialized');
  }

  posthogClient.identify(userId);
};

export function PageviewTracker({ app }: { app: string }) {
  const location = useLocation();
  const posthog = usePostHog();
  useEffect(() => {
    if (posthog) {
      posthog.capture('$pageview', {
        app,
      });
    }
  }, [location, posthog, app]);

  return null;
}

export function ShowInformationBanners() {
  const { currentUser } = useAuth();
  if (!currentUser) {
    return null;
  }

  const showRebrandingBanner =
    posthogClient?.isFeatureEnabled('notify-rebrand');

  if (showRebrandingBanner) {
    return (
      <Dialog
        defaultOpen={true}
        onOpenChange={() => {
          posthogClient?.capture('has_seen_rebranding_banner');
          posthogClient?.people.set('has_seen_rebranding_banner', 'true');
          posthogClient?.setPersonPropertiesForFlags({
            has_seen_rebranding_banner: 'true',
          });
        }}
      >
        <DialogContent>
          <DialogHeader>
            <img
              src={logo}
              alt="Installer"
              className="h-12 w-full object-contain"
            />
            <DialogTitle className="text-center my-4">
              Announcing Installer
            </DialogTitle>
            <DialogDescription className="mb-4">
              The Proptly Company is changing name to Installer. Manage
              installations with precision, flexibility and scalability.
              Installer ensures your installations matches the excellence of
              your brand.
            </DialogDescription>
            <a
              href="https://www.installer.tech"
              target="_blank"
              rel="noreferrer"
              className="w-full"
            >
              <Button>Learn more</Button>
            </a>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    );
  }

  return null;
}

export const reset = posthog.reset;
