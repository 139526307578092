import { useCallback } from 'react';

import { Dinero, toDecimal } from 'dinero.js';

import { useCurrentLang } from '@proptly/locale';

const intlFormat = (
  dineroObject: Dinero<number>,
  locale: Intl.LocalesArgument,
  options: Intl.NumberFormatOptions = {},
) => {
  return toDecimal(dineroObject, ({ value }) => {
    return Number(value).toLocaleString(locale, {
      ...options,
    });
  });
};

/**
 * @deprecated moved to ui-core
 */
export const useFormatAmountWithCurrentLang = (options?: {
  useBrowserDefaultLocale?: boolean;
}) => {
  const appLang = useCurrentLang();
  const locale = options?.useBrowserDefaultLocale ? undefined : appLang;

  return useCallback(
    (dineroObject: Dinero<number>) => {
      return intlFormat(dineroObject, locale);
    },
    [locale],
  );
};
