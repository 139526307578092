import { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { createContext, CreateContextOptions } from '@chakra-ui/react-utils';
import { createStore, StateCreator, StoreApi, useStore } from 'zustand';

export function createStoreContext<S>(
  initializer: StateCreator<S, [], [], S>,
  contextOptions?: CreateContextOptions,
) {
  const [ContextProvider, useContext] =
    createContext<StoreApi<S>>(contextOptions);

  function useStoreContext<U>(selector: (state: S) => U) {
    return useStore(useContext(), selector);
  }

  useStoreContext.useContext = useContext;

  const StoreProvider = ({
    children,
    defaultValue,
    value,
  }: PropsWithChildren<{ defaultValue?: Partial<S>; value?: Partial<S> }>) => {
    const [store] = useState(() =>
      createStore<S>((set, get, store) => ({
        ...initializer(set, get, store),
        ...defaultValue,
        ...value,
      })),
    );

    useEffect(() => {
      value && store.setState(value);
    }, [value, store]);

    return <ContextProvider value={store}>{children}</ContextProvider>;
  };

  return [StoreProvider, useStoreContext] as const;
}

type BooleanKeysOfObject<T> = {
  [P in keyof T]: T[P] extends boolean ? P : never;
}[keyof T];

export const createBooleanStoreKeyControl = <
  TStoreValue extends object,
  TFieldName extends BooleanKeysOfObject<TStoreValue>,
>(
  useContext: () => StoreApi<TStoreValue>,
  fieldName: TFieldName,
) => {
  return () => {
    const store = useContext();

    return useMemo(() => {
      return {
        on: () => store.setState({ [fieldName]: true } as Partial<TStoreValue>),
        off: () =>
          store.setState({ [fieldName]: false } as Partial<TStoreValue>),
      };
    }, [store]);
  };
};
